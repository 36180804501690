import { RootState } from '../../app/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../../services/userService'

export interface AuthState {
  loading: boolean;
  user?: IUser;
}

const initialState: AuthState = {
  loading: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    }
  }
})

export const { setLoading, setUser } = authSlice.actions

export const authLoading = (state: RootState) => state.auth.loading
export const authUser = (state: RootState) => state.auth.user

export default authSlice.reducer
