import React from 'react'
import { Button, Form, Input, Typography } from 'antd'

export type IProfileUpdateValues = {
  firstName: string;
  lastName: string;
  email: string;
}

type ProfileFormProps = {
  firstName: string;
  lastName: string;
  email: string;
  update: (values: IProfileUpdateValues) => Promise<boolean>
}

const ProfileForm: React.FC<ProfileFormProps> = ({ firstName, lastName, email, update }) => {
  const onFinish = (values: IProfileUpdateValues) => {
    update(values).then((result) => {
      console.log('ProfileForm update', result)
    })
  }
  return (
    <Form
      layout="vertical"
      initialValues={{
        firstName,
        lastName,
        email
      }}
      onFinish={onFinish}
    >
      <Typography.Title level={3}>Изменить контактные данные</Typography.Title>
      <Form.Item
        label="Имя"
        name="firstName"
        rules={[
          { required: true, message: 'Пожалуйста введите Ваше имя!' }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Фамилия"
        name="lastName"
        rules={[
          { required: true, message: 'Пожалуйста введите Вашу фамилию!' }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Пожалуйста введите ваш Email!' },
          { type: 'email', message: 'Email введен не верно' }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">Изменить</Button>
      </Form.Item>
    </Form>
  )
}

export default ProfileForm
