import './DashboardLayout.scss'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Layout, Menu, MenuProps, Result } from 'antd'
import useAuth from '../../hooks/useAuth'
import userService, { IMenu, IUser } from '../../services/userService'
import DashboardPage from '../../pages/DashboardPage/DashboardPage'
import PaperPage from '../../pages/PaperPage/PaperPage'
import Header from '../Header/Header'
import { MenuInfo } from 'rc-menu/lib/interface'
import SelectDate from '../SelectDate/SelectDate'
import { formatDate } from '../../app/helpers'
import ProfilePage from '../../pages/ProfilePage/ProfilePage'
import AdminUsersPage from '../../pages/AdminUsersPage/AdminUsersPage'

type IConfig = {
  currentDate: string,
}

const initConfig: IConfig = {
  currentDate: formatDate(new Date())
}

type DashboardLayoutProps = {
  user: IUser
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ user }) => {
  const { logout } = useAuth()
  const [config, setConfig] = useState<IConfig>(initConfig)
  const [items, setItems] = useState<MenuProps['items']>([])
  const nagivator = useNavigate()

  useEffect(() => {
    if (user.role === 1) {
      userService.getMenu()
        .then((response: IMenu) => {
          setItems(response.groups.map((group) => ({
            key: 'group-' + group.id,
            label: group.name,
            children: group.lists.map((list) => ({
              key: '/paper/' + list.id,
              label: list.name
            }))
          })))
        })
    } else {
      setItems([
        { label: 'Дашборд', key: '/' },
        { label: 'Пользователи', key: '/admin/users' }
      ])
    }
  }, [])

  const onClickMenuItem = ({ key } : MenuInfo) => {
    if (key.indexOf('/') === 0) {
      nagivator(key)
    }
  }

  const onChangeDate = (date: string) => {
    setConfig((prevState) => {
      return {
        ...prevState,
        currentDate: date
      }
    })
  }

  const routesForRole = (role: number) => {
    switch (role) {
      case 1:
        return (
          <Routes>
            <Route path="/" element={<DashboardPage date={config.currentDate} />} />
            <Route path="/paper/:id" element={<PaperPage date={config.currentDate} />} />
            <Route path="/profile" element={<ProfilePage user={user} />} />
          </Routes>
        )
      case 3:
        return (
          <Routes>
            <Route path="/" element={<DashboardPage date={config.currentDate} />} />
            <Route path="/profile" element={<ProfilePage user={user} />} />
            <Route path="/admin/users" element={<AdminUsersPage />} />
          </Routes>
        )
      default:
        return (
          <Routes>
            <Route path="*" element={<h1>error: user.role {user.role}</h1>} />
          </Routes>
        )
    }
  }

  const renderContent = () => {
    if (![1, 3].includes(user.role)) {
      return (
        <Result
          title="Доступ ограничен"
          subTitle="В ближайшее время с вами свяжется наш администратор"
        />
      )
    }
    return (
      <>
        <SelectDate currentDate={config.currentDate} onChangeDate={onChangeDate} />
        <Layout style={{ paddingTop: '24px' }}>
          <Layout.Sider className="site-layout-background" width={200}>
            <Menu
              mode="inline"
              style={{ height: '100%' }}
              items={items}
              onClick={onClickMenuItem}
            />
          </Layout.Sider>
          <Layout.Content style={{ paddingLeft: '24px', minHeight: 800 }}>
            {routesForRole(user.role)}
          </Layout.Content>
        </Layout>
      </>
    )
  }

  return (
    <Layout>
      <Header onLogout={logout} user={user} />
      <Layout.Content style={{ padding: '24px' }}>
        {renderContent()}
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>IPV Partners ©2022</Layout.Footer>
    </Layout>
  )
}

export default DashboardLayout
