import React from 'react'
import ProfileForm, { IProfileUpdateValues } from '../../components/ProfileForm/ProfileForm'
import PasswordForm, { IPasswordUpdateValues } from '../../components/PasswordForm/PasswordForm'
import { Typography, Divider, message } from 'antd'
import { IUser } from '../../services/userService'

type ProfilePageProps = {
  user: IUser;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ user }) => {
  const handleUpdateProfile = async (values: IProfileUpdateValues) => {
    console.log('handleUpdateProfile', values)
    message.success('Контактные данные обновлены')
    return true
  }
  const handleUpdatePassword = async (values: IPasswordUpdateValues) => {
    console.log('handleUpdatePassword', values)
    message.success('Новый пароль установлен')
    return true
  }
  return (
    <div>
      <Typography.Title>Профиль пользователя</Typography.Title>
      <div className="site-content">
        <ProfileForm
          update={handleUpdateProfile}
          firstName={user.firstName}
          lastName={user.lastName}
          email={user.email} />
        <Divider />
        <PasswordForm
          update={handleUpdatePassword} />
      </div>
    </div>
  )
}

export default ProfilePage
