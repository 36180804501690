import './Logo.scss'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as LogoSVG } from './logo.svg'

const Logo: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className="Logo">
      <LogoSVG onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
    </div>
  )
}

export default Logo
