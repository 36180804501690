import './RestoreEmailForm.scss'
import React from 'react'
import {
  Button, Form, Input, message, Typography
} from 'antd'
import useAuth from '../../hooks/useAuth'

type RestoreEmailFormType = {
  email: string;
}

const RestoreEmailForm: React.FC = () => {
  const { restoreLink } = useAuth()

  const onFinish = (values: RestoreEmailFormType) => {
    restoreLink(values.email)
      .then((data) => {
        if (data.status) {
          message.success(data.data)
        } else {
          message.error(data.data)
        }
      })
      .catch((error) => message.error(error.response.data.message))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className="RestoreEmailForm">
      <Typography.Title>Восстановление пароля</Typography.Title>
      <Typography.Paragraph>Пожалуйста, введите вашу электронную почту и мы отправить вам письмо с инструкцией</Typography.Paragraph>
       <Form
        name="basic"
        layout="vertical"
        labelAlign="left"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
       >
         <Form.Item
           label="Email"
           name="email"
           rules={[
             { required: true, message: 'Пожалуйста введите ваш Email!' },
             { type: 'email', message: 'Email введен не верно' }
           ]}
         >
           <Input />
         </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Получить письмо
          </Button>
        </Form.Item>

       </Form>
    </div>
  )
}

export default RestoreEmailForm
