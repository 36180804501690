import React from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'

type SelectDateProps = {
  currentDate: string;
  onChangeDate: (date: any) => void;
}

const SelectDate: React.FC<SelectDateProps> = ({ currentDate, onChangeDate }) => {
  const dateFormatFromBack = 'YYYY-MM-DD'
  const dateFormatDisplay = 'M/D/YYYY'
  return (
    <DatePicker
      clearIcon={false}
      defaultValue={moment(currentDate, dateFormatFromBack)}
      format={dateFormatDisplay} onChange={(date: any) => onChangeDate(date.format('YYYY-MM-DD'))}
    />
  )
}

export default SelectDate
