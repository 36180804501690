import React from 'react'
import { ReactComponent as LineTopSVG } from './line-top.svg'
import { ReactComponent as LineBottomSVG } from './line-bottom.svg'

function LoginForm () {
  return (
    <div>
      <LineTopSVG style={{ position: 'absolute' }} />
      <LineBottomSVG style={{ position: 'absolute', bottom: '0', right: '0' }} />
      <div style={{ color: '#FFF', padding: '40px 40px 40px 80px' }}>
        <div style={{
          display: 'flex', justifyContent: 'space-between', fontSize: '20px', opacity: 0.8, paddingBottom: '100px'
        }}
        >
          <div>IPV Partners</div>
          <div>2022</div>
        </div>
        <div style={{
          fontSize: '40px', lineHeight: '48px', maxWidth: '500px', paddingBottom: '20px'
        }}
        >
          Платформа рыночных данных по деривативам
        </div>
        <div style={{
          fontSize: '16px', lineHeight: '24px', maxWidth: '300px', opacity: 0.8
        }}
        >
          Строгий контроль качества гарантирует точность и полноту данных
        </div>
      </div>

    </div>
  )
}

export default LoginForm
