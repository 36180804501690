import { authAPI } from './apiService'

const adminService = {
  getUsers: () => authAPI.get('/admin/users')
    .then((response) => {
      return response.data
    }),
  closeAccess: (userId: number) => authAPI.post('/admin/set-guest-role', { userId })
    .then((response) => {
      return response.data
    }),
  openAccess: (userId: number) => authAPI.post('/admin/set-user-role', { userId })
    .then((response) => {
      return response.data
    })
}

export default adminService
