import './LogoWhite.scss'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as LogoWhiteSVG } from './logo-white.svg'

const LogoWhite: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div
      className="LogoWhite"
      onClick={() => navigate('/')}
      style={{ cursor: 'pointer' }}
    >
      <LogoWhiteSVG/>
      IPV Partners
    </div>
  )
}

export default LogoWhite
