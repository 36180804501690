import React from 'react'
import { Link } from 'react-router-dom'
import {
  Avatar, Button, Layout, Space
} from 'antd'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import LogoWhite from '../LogoWhite/LogoWhite'
import { IUser } from '../../services/userService'

type HeaderProps = {
  onLogout: () => void;
  user: IUser | null;
}

const Header: React.FC<HeaderProps> = ({ onLogout, user }) => (
  <Layout.Header className="header">
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <LogoWhite />
      </div>
      {user && (
        <Space size="middle">
          <Avatar size="large" icon={<UserOutlined />} />
          <Link to="/profile">
            <div style={{ color: '#fff', lineHeight: '20px', paddingRight: '20px' }}>
              <div>{`${user.firstName} ${user.lastName}`}</div>
              <div style={{ opacity: '0.8' }}>{user.email}</div>
            </div>
          </Link>
          <Button onClick={onLogout} shape="circle" icon={<LogoutOutlined />} size="large" />
        </Space>
      )}
    </div>
  </Layout.Header>
)

export default Header
