import React from 'react'
import { Button, Form, Input, Typography } from 'antd'

export type IPasswordUpdateValues = {
  oldPassword: string;
  newPassword: string;
  confirm: string;
}

type PasswordFormProps = {
  update: (values: IPasswordUpdateValues) => Promise<boolean>
}
const PasswordForm: React.FC<PasswordFormProps> = ({ update }) => {
  const [form] = Form.useForm()

  const onFinish = (values: IPasswordUpdateValues) => {
    update(values).then((result) => {
      console.log('PasswordForm update', result)
      form.setFieldsValue({
        oldPassword: '',
        newPassword: '',
        confirm: ''
      })
    })
  }
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirm: ''
      }}
      onFinish={onFinish}
    >
      <Typography.Title level={3}>Смена пароля</Typography.Title>
      <Form.Item
        label="Старый"
        name="oldPassword"
        rules={[{ required: true, message: 'Пожалуйста введите ваш пароль!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Пароль"
        name="newPassword"
        rules={[{ required: true, message: 'Пожалуйста введите ваш пароль!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Подтвердите пароль"
        dependencies={['newPassword']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Пожалуйста введите ваш пароль!'
          },
          ({ getFieldValue }) => ({
            validator (_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Пароли должны совпадать'))
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">Изменить</Button>
      </Form.Item>
    </Form>
  )
}

export default PasswordForm
