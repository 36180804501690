import { authAPI, noAuthAPI } from './apiService'

export type IUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: number;
}

export type IList = {
  id: number;
  name: string;
}

export type IGroupWithLists = {
  id: number;
  name: string;
  lists: IList[],
}

export type IMenu = {
  groups: IGroupWithLists[];
}

export type IField = {
  id: number;
  type: number;
  name: string;
  position: number;
  isTooltip: boolean;
}

export type IStaticValue = {
  id: number;
  fieldId: number;
  value: string;
}

export type IValue = {
  quotationId: number;
  fieldId: number;
  value: number | null;
}

export type IConsensusValue = {
  quotationId: number;
  fieldId: number;
  value: number;
}

export type IQuotation = {
  id: number;
  staticValues: IStaticValue[];
  values?: IValue[];
  consensusValues?: IConsensusValue[];
}

export type IGroupWithFields = {
  id: number;
  name: string;
  fields: IField[],
}

export type Access = {
  allow: boolean;
}

export type IPaper = {
  name: string;
  description: string;
  group: IGroupWithFields;
  quotations: IQuotation[];
  accesses?: Access[];
}

const userService = {
  getProfile: () => authAPI.get('/users/profile')
    .then((response) => {
      return response.data
    }),
  getMenu: () => authAPI.get('/dashboard/menu')
    .then((response) => {
      return response.data
    }),
  getPaper: (id: number, date: string) => authAPI.get<IPaper>('/dashboard/list/' + id + '/' + date)
    .then((response) => {
      return response.data
    }),
  getConsensus: (id: number, date: string) => authAPI.get<IPaper>('/dashboard/list/' + id + '/' + date + '/consensus')
    .then((response) => {
      return response.data
    }),
  postValues: (id: number, values: any) => authAPI.post('/dashboard/list/' + id, { values })
    .then((response) => {
      return response.data
    }),
  calculate: () => noAuthAPI.get('/tasks/run')
    .then((response) => {
      return response.data
    }),
  resetValues: (id: number) => authAPI.delete('/dashboard/list/' + id)
    .then(() => {
      return true
    })
}

export default userService
