import React, { useEffect, useState } from 'react'
import { Typography, Table, Tag, Button, message } from 'antd'
import adminService from '../../services/adminService'

type AdminUsersPageProps = {
}

const AdminUsersPage: React.FC<AdminUsersPageProps> = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<any[]>([])
  const fetchData = () => {
    adminService.getUsers().then((data) => {
      const newDataSource: any[] = []
      data.map((user: any) => {
        newDataSource.push({
          key: user.id.toString(),
          ...user
        })
        return user
      })
      setDataSource(newDataSource)
    })
  }
  const onCloseAccess = (userId: number) => {
    setLoading(true)
    adminService.closeAccess(userId)
      .then(() => {
        setLoading(false)
        message.success('Доступ закрыт')
        fetchData()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onOpenAccess = (userId: number) => {
    setLoading(true)
    adminService.openAccess(userId)
      .then(() => {
        setLoading(false)
        message.success('Доступ открыт')
        fetchData()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      width: '100px',
      title: 'Доступ',
      dataIndex: 'role',
      key: 'role',
      render: (_: any, { role }: any) => (
        <Tag color={role === 1 ? 'green' : 'volcano'}>
          {role === 1 ? 'Открыт' : 'Закрыт'}
        </Tag>
      )
    },
    {
      width: '180px',
      title: 'Действие',
      key: 'action',
      render: (_: any, record: any) => record.role === 1
        ? (<Button onClick={() => onCloseAccess(record.id)} type="link">Закрыть доступ</Button>)
        : (<Button onClick={() => onOpenAccess(record.id)} type="link">Открыть доступ</Button>)
    }
  ]
  return (
    <div>
      <Typography.Title>Управление пользователями</Typography.Title>
      <Table loading={loading} dataSource={dataSource} columns={columns} />;
    </div>
  )
}

export default AdminUsersPage
