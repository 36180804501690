import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography, Tabs, Spin, message, Result, notification, Alert, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import userService, { IPaper } from '../../services/userService'
import WorkList from '../../components/WorkList/WorkList'
import tokenService from '../../services/tokenService'
import { API_URL } from '../../services/apiService'
type PaperPageProps = {
  date: string
}
const PaperPage: React.FC<PaperPageProps> = ({ date }) => {
  const params = useParams()
  const id = parseInt(params.id || '')
  const [loading, setLoading] = useState(false)
  const [paper, setPaper] = useState<IPaper>()
  const [consensus, setConsensus] = useState<IPaper>()
  const fetchData = () => {
    return Promise.all([
      userService.getPaper(id, date),
      userService.getConsensus(id, date)
    ]).then(([paperData, consensusData]) => {
      setPaper(paperData)
      setConsensus(consensusData)
      return true
    })
  }
  const handleSubmit = (values: any) => {
    setLoading(true)
    return userService.postValues(id, values)
      .then(() => userService.calculate().then(() => fetchData().then(() => {
        setLoading(false)
        message.success('Данные отправлены!')
      }))).catch((error) => {
        setLoading(false)
        notification.error({
          message: 'Ошибка',
          description: error.response.data.message,
          placement: 'bottom'
        })
      })
  }
  const handleReset = () => {
    setLoading(true)
    return userService.resetValues(id).then(() => fetchData().then(() => {
      setLoading(false)
      message.success('Данные сброшены!')
    }))
  }
  useEffect(() => {
    setLoading(true)
    fetchData().then(() => {
      setLoading(false)
    })
  }, [id, date])
  const handleDownload = () => {
    const token = tokenService.get()
    window.location.href = `${API_URL}/dashboard/list/${id}/${date}/csv?token=${token}`
  }
  return (
    <Spin spinning={loading}>
      {paper && consensus && (
        <>
          <Typography.Title>{paper.name}</Typography.Title>
          <Typography.Title level={3}>{paper.description}</Typography.Title>
          {paper.quotations.length > 0 && (
            <Tabs defaultActiveKey="1" type="card" size="large" tabBarExtraContent={{ right: <Button size="large" onClick={handleDownload} icon={<DownloadOutlined />}>Скачать .csv</Button> }}>
              <Tabs.TabPane tab="Input Data" key="1">
                <WorkList paper={paper} handleSubmit={handleSubmit} handleReset={handleReset}/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Consensus" key="2">
                {!consensus.quotations[0].consensusValues?.length
                  ? <Result title="За выбранную дату консенсус не рассчитан" />
                  : (
                    <>
                      {consensus.accesses && consensus.accesses.length > 0 && !consensus.accesses[0].allow && (
                        <Alert message="Ваши данные не попали в консенсус." type="error" banner />
                      )}
                      <WorkList paper={consensus} isConsensus />
                    </>
                    )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </>
      )}
    </Spin>
  )
}

export default PaperPage
