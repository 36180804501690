import './WorkList.scss'
import React, { ClipboardEvent, useContext, useEffect, useState } from 'react'
import { FormInstance } from 'antd/lib/form'
import { Input, Table, Form, Button, Tooltip, Popconfirm } from 'antd'
import { IConsensusValue, IField, IPaper, IQuotation, IStaticValue, IValue } from '../../services/userService'

const PasteContext = React.createContext({})
const TooltipContext = React.createContext({})
const DisabledContext = React.createContext(false)
const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface Item {
  key: string;
  // name: string;
  // age: string;
  // address: string;
}

interface EditableRowProps {
  index: number;
  'data-row-key': number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const getTooltip = useContext<any>(TooltipContext)
  const [form] = Form.useForm()
  const key = props['data-row-key']
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <Tooltip title={getTooltip(key)}>
          <tr {...props} />
        </Tooltip>
      </EditableContext.Provider>
    </Form>
  )
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  if (!editable) {
    return <td {...restProps}>{children}</td>
  }
  const form = useContext(EditableContext)!
  const disabledContext = useContext(DisabledContext)
  const onPaste = useContext<any>(PasteContext)
  const onBlurHandle = async () => {
    try {
      const values = await form.validateFields()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }
  const onPasteHandle = (e: ClipboardEvent) => {
    const str = e.clipboardData.getData('Text')
    if (onPaste(str)) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    if (record && Object.hasOwn(record, dataIndex)) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
    }
  },
  [record])
  return (
    <td {...restProps}>
      <Form.Item
        style={{ margin: 0, width: '100px' }}
        name={dataIndex}
      >
        <Input
          disabled={disabledContext}
          placeholder="0.0"
          onBlur={onBlurHandle}
          onPaste={onPasteHandle}/>
      </Form.Item>
    </td>
  )
}

type EditableTableProps = Parameters<typeof Table>[0];

// type DataType = Map<string, string | number>;

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

type TooltipComponentProps = {
  quotation: IQuotation;
  fields: IField[];
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({ fields, quotation }) => {
  const isTooltipFields = fields.filter((field) => field.isTooltip)
  const findValue = (id: number) => {
    return quotation.staticValues.find((staticValue) => staticValue.fieldId === id)?.value
  }
  return (
    <div>
      {isTooltipFields.map((field) => <div key={field.id}>{field.name}: {findValue(field.id)}</div>)}
    </div>
  )
}

type WorkListProps = {
  paper: IPaper;
  isConsensus?: boolean;
  handleSubmit?: (values: any) => Promise<any>;
  handleReset?: () => Promise<any>;
}

const WorkList: React.FC<WorkListProps> = ({ paper, handleSubmit, handleReset, isConsensus = false }) => {
  const [columns, setColumns] = useState<(ColumnTypes[number] & { editable?: boolean; dataIndex: string })[]>([])
  const [dataSource, setDataSource] = useState<any[]>([])
  const [disabledState, setDisabledState] = useState(false)

  useEffect(() => {
    if (paper) {
      const fields = paper.group.fields.sort((a, b) => a.position - b.position)
      const newColumns = fields.filter((field) => !field.isTooltip).map((field) => {
        const col = {
          title: field.name,
          dataIndex: field.id.toString(),
          editable: !isConsensus && field.type === 2
        }
        if (!col.editable) {
          return col
        }
        return {
          ...col,
          onCell: (record: any) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave
          })
        }
      })
      let isHaveValues = false
      const newDataSource = paper.quotations.map((quotation) => {
        return newColumns.reduce((obj, column) => {
          let value: undefined | IStaticValue | IValue | IConsensusValue
          value = quotation.staticValues.find((value) => value.fieldId.toString() === column.dataIndex)
          if (!value && quotation.values?.length) {
            isHaveValues = true
            value = quotation.values.find((value) => value.fieldId.toString() === column.dataIndex)
          }
          if (!value && quotation.consensusValues?.length) {
            value = quotation.consensusValues.find((value) => value.fieldId.toString() === column.dataIndex)
          }
          return {
            ...obj,
            [column.dataIndex]: value ? value.value : ''
          }
        }, { key: quotation.id })
      })
      setDisabledState(isHaveValues)
      setColumns(newColumns)
      setDataSource(newDataSource)
    }
  }, [paper])

  // const handleSave = (row: DataType) => {
  const handleSave = (row: any) => {
    setDataSource((prevState) => {
      const newData = [...prevState]
      const index = newData.findIndex((item) => row.key === item.key)
      const item = newData[index]
      newData.splice(index, 1, {
        ...item,
        ...row
      })
      return newData
    })
  }

  const onClickReset = () => {
    if (typeof handleReset !== 'function') return
    handleReset()
  }

  const onClickSubmit = () => {
    if (typeof handleSubmit !== 'function') return
    const inputFieldIds = paper.group.fields.filter((field) => field.type === 2).map((field) => field.id)
    const paperQuotations = paper.quotations
    const values: IValue[] = []
    for (let i = 0; i < dataSource.length; i++) {
      for (let ii = 0; ii < inputFieldIds.length; ii++) {
        const value = dataSource[i][inputFieldIds[ii]]
        const quotationData = paperQuotations.find((value) => dataSource[i].key === value.id)
        const valueData = (quotationData && quotationData.values) ? quotationData.values.find((value) => inputFieldIds[ii] === value.fieldId) : null
        if (!value || valueData) {
          continue
        }
        values.push({
          quotationId: dataSource[i].key,
          fieldId: inputFieldIds[ii],
          value: value ? parseFloat(value.replace(',', '.')) : null
        })
      }
    }
    handleSubmit(values)
  }

  const onPaste = (str: string) => {
    let charNewLine
    if (/Macintosh/.test(navigator.userAgent)) {
      charNewLine = '\n'
    } else if (/Windows NT/.test(navigator.userAgent)) {
      charNewLine = '\r\n'
    } else {
      return
    }
    const charTab = '\t'
    const rows = str.split(charNewLine)
    const table = rows.map((row) => row.split(charTab))
    const isTablePaste = rows.length + table.length > 2
    if (isTablePaste) {
      setTimeout(() => {
        setDataSource((prevState) => {
          const inputFieldIds = paper.group.fields.filter((field) => field.type === 2).map((field) => field.id)
          for (let i = 0; i < Math.min(table.length, dataSource.length); i++) {
            for (let ii = 0; ii < inputFieldIds.length; ii++) {
              prevState[i][inputFieldIds[ii]] = table[i][ii]
            }
          }
          return JSON.parse(JSON.stringify(prevState))
        })
      }, 100)
    }
    return isTablePaste
  }

  const getTooltip = (key: number) => {
    const quotation = paper.quotations.find((quotation) => quotation.id === key)
    if (!quotation) {
      return <></>
    }
    return <TooltipComponent fields={paper.group.fields} quotation={quotation}/>
  }

  const actionsPanel = (
    <div className="work-list-bottom-button">
      { disabledState
        ? (
          <Popconfirm
            title="Вы уверены?"
            onConfirm={onClickReset}
            okText="Да"
            cancelText="Нет"
          >
            <Button size="large" type="primary" danger>Сброс</Button>
          </Popconfirm>)
        : (
          <Button size="large" type="primary" onClick={onClickSubmit}>Отправить</Button>
          )
      }
    </div>
  )

  return (
    <div className="work-list">
      <TooltipContext.Provider value={getTooltip}>
        <DisabledContext.Provider value={disabledState}>
          <PasteContext.Provider value={onPaste}>
            <Table
              components={{
                body: {
                  row: EditableRow,
                  cell: EditableCell
                }
              }}
              dataSource={dataSource}
              columns={columns as ColumnTypes}
              pagination={false}
              scroll={{ x: true }}
            />
          </PasteContext.Provider>
        </DisabledContext.Provider>
      </TooltipContext.Provider>
      {!isConsensus && actionsPanel}
    </div>
  )
}

export default WorkList
