import React, { useEffect, useRef } from 'react'
import useAuth from '../../hooks/useAuth'
import DashboardLayout from '../DashboardLayout/DashboardLayout'
import NoAuthLayout from '../NoAuthLayout/NoAuthLayout'
import tokenService from '../../services/tokenService'
import { Spin } from 'antd'

const LayoutSwitcher = () => {
  const { user, fetchUser, loading } = useAuth()
  const calledOnce = useRef(false)

  useEffect(() => {
    if (calledOnce.current) return
    calledOnce.current = true
    const savedToken = tokenService.get()
    if (savedToken) {
      fetchUser()
    }
  })

  return (
    <Spin spinning={loading}>
      {user ? <DashboardLayout user={user}/> : <NoAuthLayout/>}
    </Spin>
  )
}

export default LayoutSwitcher
