import React from 'react'
import 'antd/dist/antd.min.css'
import './App.scss'
import { BrowserRouter } from 'react-router-dom'
import LayoutSwitcher from './components/LayoutSwitcher/LayoutSwitcher'

function App () {
  return (
    <BrowserRouter>
       <LayoutSwitcher />
    </BrowserRouter>
  )
}

export default App
