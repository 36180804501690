import React from 'react'
import { Col, Row } from 'antd'
import Logo from '../../components/Logo/Logo'
import Banner from '../../components/Banner/Banner'
import RestorePasswordForm from '../../components/RestorePasswordForm/RestorePasswordForm'

function RestorePasswordPage () {
  return (
    <Row style={{ height: '100%' }}>
      <Col span={12}>
        <div style={{ padding: '20px', position: 'absolute' }}>
          <Logo />
        </div>
        <div style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        >
          <RestorePasswordForm />
        </div>
      </Col>
      <Col span={12} style={{ backgroundColor: '#194185', overflow: 'hidden' }}>
        <Banner />
      </Col>
    </Row>
  )
}

export default RestorePasswordPage
