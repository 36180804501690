import './LoginForm.scss'
import React from 'react'
import { Button, Form, Input, message, Checkbox, Typography } from 'antd'

import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const { Title, Paragraph, Text } = Typography

type LoginFormValues = {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const navigate = useNavigate()
  const { login } = useAuth()
  const onFinish = (values: LoginFormValues) => {
    login(values.email, values.password)
      .catch((error) => {
        message.error(error.response.data.message)
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`)
  }

  return (
    <div className="LoginForm">
      <Title>Вход на платформу</Title>
      <Paragraph>Пожалуйста, введите ваши данные</Paragraph>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Эл. почта"
          name="email"
          rules={[
            { required: true, message: 'Пожалуйста введите ваш Email!' },
            { type: 'email', message: 'Email введен не верно' }
          ]}
        >
          <Input placeholder="Введите электронную почту" />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: true, message: 'Пожалуйста введите ваш пароль!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Checkbox onChange={onChange}>Запомнить меня</Checkbox>
          <Button type="link" onClick={() => navigate('/restoreEmail')}>Забыли пароль?</Button>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Войти
          </Button>
        </Form.Item>

        <Form.Item>
          <Text>Еще нет аккаунта?</Text>
          <Button type="link" onClick={() => navigate('/register')}>Регистрация</Button>
        </Form.Item>

      </Form>
    </div>
  )
}

export default LoginForm
