import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginPage from '../../pages/LoginPage/LoginPage'
import RegistrationPage from '../../pages/RegistrationPage/RegistrationPage'
import RestoreEmailPage from '../../pages/RestoreEmailPage/RestoreEmailPage'
import RestorePasswordPage from '../../pages/RestorePasswordPage/RestorePasswordPage'

const NoAuthLayout: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/register" element={<RegistrationPage />} />
      <Route path="/restoreEmail" element={<RestoreEmailPage />} />
      <Route path="/restorePassword" element={<RestorePasswordPage />} />
    </Routes>
  )
}

export default NoAuthLayout
