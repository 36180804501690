import axios from 'axios'
import { APP_ENV } from '../app/enviroment'
import tokenService from './tokenService'

export const API_URL = APP_ENV.REACT_APP_API_URL || ''

export const noAuthAPI = axios.create({
  baseURL: API_URL
})

export const authAPI = axios.create({
  baseURL: API_URL
})

authAPI.interceptors.request.use((config) => {
  const accessToken = tokenService.get()
  if (accessToken) {
    config.headers!.Authorization = `Bearer ${accessToken}`
  }
  return config
})

authAPI.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error && error.response && error.response.status === 401) {
    tokenService.remove()
    // @ts-ignore
    window.location = '/'
  } else {
    return Promise.reject(error)
  }
})
