import React from 'react'
import { Typography } from 'antd'

type DashboardPageProps = {
  date: string
}

const DashboardPage: React.FC<DashboardPageProps> = ({ date }) => {
  return (
    <Typography.Title>Добро пожаловать</Typography.Title>
  )
}

export default DashboardPage
