import './RestorePasswordForm.scss'
import React from 'react'
import {
  Button, Form, Input, message, Typography
} from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

type restorePasswordForm = {
  password: string;
}

const RestorePasswordForm: React.FC = () => {
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const hash = searchParams.get('hash')

  if (!email || !hash) {
    return (
      <div className="RestoreForm">
        <Typography.Title>Восстановление пароля</Typography.Title>
        <Typography.Paragraph>Нерабочая ссылка</Typography.Paragraph>
      </div>
    )
  }

  const { restorePassword } = useAuth()
  const navigate = useNavigate()

  const onFinish = (values: restorePasswordForm) => {
    restorePassword(email, hash, values.password)
      .then((data) => {
        if (data.status) {
          message.success(data.data)
          navigate('/')
        } else {
          message.error(data.data)
        }
      })
      .catch((error) => message.error(error.response.data.message))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className="RestorePasswordForm">
      <Typography.Title>Восстановление пароля</Typography.Title>
      <Typography.Paragraph>Пожалуйста, введите ваш новый пароль</Typography.Paragraph>
       <Form
        name="basic"
        layout="vertical"
        labelAlign="left"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
       >
        <Form.Item
          label="Новый пароль"
          name="password"
          rules={[{ required: true, message: 'Пожалуйста введите ваш пароль!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Подтвердите пароль"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Пожалуйста введите ваш пароль!'
            },
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Пароли должны совпадать'))
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Изменить пароль
          </Button>
        </Form.Item>

       </Form>
    </div>
  )
}

export default RestorePasswordForm
